import React from 'react';
import { Button, DatePicker, Form, Input, Select, Checkbox, Divider } from 'antd';

import { RegField, RegDetailsModel, RegSelectModel } from '../../types';

const StudentForm: React.FC<{
  studentFields: RegField[];
  regInfo: RegDetailsModel;
  initialValues: any;
  regSelects: RegSelectModel;

  onBack: (values: any) => void;
  onSubmit: (values: any) => void;
}> = ({ studentFields, regInfo, initialValues, regSelects, onBack, onSubmit }) => {
  const [form] = Form.useForm();

  const handleBackClick = () => {
    onBack(form.getFieldsValue());
  };

  const renderTextField = (field: string, label: string, required: boolean) => (
    <Form.Item
      name={field}
      label={label}
      required={false}
      style={{ marginBottom: 8 }}
      rules={required ? [{ required: true, message: 'Required' }] : []}
    >
      <Input />
    </Form.Item>
  );

  const renderSelectField = (
    field: string,
    label: string,
    required: boolean,
    options: { value: string; label: string }[]
  ) => (
    <Form.Item
      name={field}
      label={label}
      required={false}
      style={{ marginBottom: 8 }}
      rules={required ? [{ required: true, message: 'Required' }] : []}
    >
      <Select options={options} />
    </Form.Item>
  );

  const renderCheckboxField = (field: string, label: string, required: boolean) => (
    <Form.Item
      name={field}
      label={label}
      required={false}
      style={{ marginBottom: 8 }}
      valuePropName='checked'
      rules={required ? [{ required: true, message: 'Required' }] : []}
    >
      <Checkbox />
    </Form.Item>
  );

  const renderDateField = (field: string, label: string, required: boolean) => (
    <Form.Item
      name={field}
      label={label}
      required={false}
      style={{ marginBottom: 8 }}
      rules={required ? [{ required: true, message: 'Required' }] : []}
    >
      <DatePicker format='DD MMM YYYY' />
    </Form.Item>
  );

  const renderInstumentPrefrence = (i: number) => (
    <Form.Item
      name={['instrumentPrefrence', i]}
      label={`Preference ${i + 1}`}
      required={false}
      style={{ marginBottom: 8 }}
      rules={[{ required: true, message: 'Required' }]}
    >
      <Select options={regInfo.instruments} fieldNames={{ value: 'id', label: 'listItem' }} />
    </Form.Item>
  );

  const renderStudentFields = () => {
    return studentFields.map((field) => {
      switch (field.fieldType) {
        case 1:
          return renderTextField(field.fieldName, field.fieldLabel, field.requiredField);
        case 2:
          return renderCheckboxField(field.fieldName, field.fieldLabel, field.requiredField);
        case 3:
          return renderDateField(field.fieldName, field.fieldLabel, field.requiredField);
        case 4:
          const options =
            regSelects[field.fieldName] ||
            (field.fieldValues && field.fieldValues.map((item) => ({ value: item, label: item }))) ||
            [];
          return renderSelectField(field.fieldName, field.fieldLabel, field.requiredField, options);

        default:
          return null;
      }
    });
  };

  const renderGroups = () => (
    <>
      <Divider />
      <Form.Item
        name='groupId'
        label='Band / Esemble'
        required={false}
        style={{ marginBottom: 8 }}
        rules={[{ required: true, message: 'Required' }]}
        hidden={regInfo.formGroups.length === 1}
      >
        <Select
          options={regInfo.formGroups}
          fieldNames={{ value: 'id', label: 'groupName' }}
          defaultValue={regInfo.formGroups[0].id}
        />
      </Form.Item>
    </>
  );

  return (
    <>
      <Form
        form={form}
        labelCol={{ span: 6 }}
        name='regStudentForm'
        autoComplete='off'
        initialValues={initialValues}
        onFinish={onSubmit}
      >
        <h3 className='cardheader'>Student Information</h3>
        <div className='cardbody p-4 pr-5'>
          <Form.Item
            name='firstName'
            label='First Name'
            required={false}
            style={{ marginBottom: 8 }}
            rules={[{ required: true, message: 'Required' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='lastName'
            label='Last Name'
            required={false}
            style={{ marginBottom: 8 }}
            rules={[{ required: true, message: 'Required' }]}
          >
            <Input />
          </Form.Item>
          {renderStudentFields()}
          {regInfo.formGroups.length > 1 && renderGroups()}

          <Divider orientation='left' plain>
            Instrument Prefrence
          </Divider>
          {Array.from(Array(regInfo.instrumentPreferences).keys()).map((i) => renderInstumentPrefrence(i))}
        </div>

        <div className='cardbody p-3 no-header'>
          <div className='d-flex justify-content-between'>
            {regInfo.formText.length > 20 || regInfo.formTerms.length > 20 ? (
              <Button onClick={handleBackClick}>Back</Button>
            ) : (
              <div />
            )}
            <Button type='primary' htmlType='submit'>
              Next
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
};

export default StudentForm;
