import React from 'react';

const PortalFooter: React.FC<{}> = ({}) => {
  return (
    <footer className='app-footer border-top'>
      <div className='mb-1 text-right'>
        <span className='logo-light'>Powered by </span>
        <strong>
          <span className='logo-blue'>My</span>
          <span className='logo-dark'>School</span>
          <span className='logo-blue'>Music</span>
        </strong>
      </div>
    </footer>
  );
};

export default PortalFooter;
