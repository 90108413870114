import React, { useState, useEffect } from 'react';
import { notification } from 'antd';
import GoogleSignIn from '../portal/GoogleLogin';
import LoginForm from '../portal/LoginForm';
import EmailValidate from './EmailValidate';

import api from '../../api';

import { LoginDetails } from '../../types';

const RegLogin: React.FC<{
  schoolId: number;
  onLogin: (loginDetails: LoginDetails) => void;
  existingContact: (token: string) => void;
  linkedStudents: (students: string[]) => void;
}> = ({ schoolId, onLogin, existingContact, linkedStudents }) => {
  const [validate, setValidate] = useState();
  const [showLogin, setShowLogin] = useState(false);
  const [loginDetails, setLoginDetails] = useState<LoginDetails>();

  useEffect(() => {
    async function tokenRegister(token: string) {
      try {
        const response = await api.get('/portal/tokenRegister');
        if (response.data) {
          linkedStudents(response.data.linkedStudents);
          existingContact(token);
        } else {
          setShowLogin(true);
        }
      } catch (error) {
        setShowLogin(true);
      }
    }

    const token = localStorage.getItem('token');
    if (token) {
      tokenRegister(token);
    } else {
      setShowLogin(true);
    }
  }, []);

  const localLogin = async (values: { email: string; password: string }) => {
    const response = await api.post('/portal/localRegister', { ...values, schoolId }).catch((error) => {});
    if (response?.data) {
      linkedStudents(response.data.linkedStudents);
      if (response.data.validate) {
        setValidate(response.data.validate);
        setLoginDetails(values);
      } else if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        existingContact(response.data.token);
      }
    }
  };

  const onGoogleLogin = async (token: any) => {
    const result = await api.post('/portal/googlesRegister', { token, schoolId }).catch((error) => {
      notification.warning({ message: error?.response?.data || 'There was a problem signing you in', duration: 7 });
    });
    if (result?.data) {
      linkedStudents(result.data.linkedStudents);
      if (result.data.token) {
        localStorage.setItem('token', result.data.token);
        existingContact(result.data.token);
      } else {
        const { email, sub, picture } = result.data;
        onLogin({ email, sub, picture });
      }
    }
  };

  const handleVerifyCode = async (code: string | undefined) => {
    try {
      if (code) {
        const response = await api.post('/portal/emailvalidate', { code, validate });
        if (response?.status === 200 && loginDetails) {
          onLogin({ ...loginDetails, validate });
        }
      }
    } catch (error: any) {
      notification.warning({
        message: error?.response?.data?.message || 'There was a problem verifying the code',
        duration: 7,
      });
    }
  };

  if (!showLogin) return null;

  if (validate) return <EmailValidate back={() => setValidate(undefined)} verifyCode={handleVerifyCode} />;

  return (
    <div className='w-500 center-card'>
      <h3 className='cardheader'>Login or create portal account</h3>
      <div className='cardbody'>
        <p className='pb-3'>
          If you have a portal account then login below to proceed or use the same form to create a new portal account
        </p>
        <div className='p-2 border rounded'>
          <h5 className='text-center mb-4'>Login / Create Login</h5>
          <GoogleSignIn onGoogle={onGoogleLogin} />
          <div className='divider divider-with-content my-4'>
            <span className='divider-inner-content'>OR</span>
          </div>

          <LoginForm onLogin={localLogin} register={true} />
        </div>
      </div>
    </div>
  );
};

export default RegLogin;
