import React from 'react';
import { Descriptions } from 'antd';

import { StudentDetails } from '../../types';

const Student: React.FC<{ studentDetails: StudentDetails }> = ({ studentDetails }) => {
  const { firstName, lastName, grade, studentGroups } = studentDetails;
  const cssTop = { verticalAlign: 'text-top' };

  const renderBands = () => {
    return (
      <ul className='no-bullets'>
        {studentGroups.map((group, index: number) => (
          <li key={index}>{group.groupName}</li>
        ))}
      </ul>
    );
  };

  const renderInstruments = () => {
    return (
      <ul className='no-bullets'>
        {studentGroups
          .filter((group) => group.instrument)
          .map((group, index: number) => (
            <li key={index}>{group.instrument}</li>
          ))}
      </ul>
    );
  };

  return (
    <>
      <h3 className='cardheader'>Student</h3>
      <div className='cardbody'>
        <Descriptions column={1} bordered size='small'>
          <Descriptions.Item label='Name'>{`${firstName} ${lastName}`}</Descriptions.Item>
          <Descriptions.Item label='Grade'>{grade}</Descriptions.Item>
          <Descriptions.Item label='Bands' labelStyle={cssTop}>
            {renderBands()}
          </Descriptions.Item>
          <Descriptions.Item label='Instruments' labelStyle={cssTop}>
            {renderInstruments()}
          </Descriptions.Item>
          <Descriptions.Item label='Rehearsals'>18:00:00</Descriptions.Item>
        </Descriptions>
      </div>
    </>
  );
};

export default Student;
