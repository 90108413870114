import React from 'react';
import { Form, Input, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

const SignupForm: React.FC<{
  onSignup: (values: { email: string; password: string }) => void;
}> = ({ onSignup }) => {
  return (
    <Form name='normal_login' onFinish={onSignup}>
      <Form.Item name='email' rules={[{ required: true, message: 'Please enter your email address!' }]}>
        <Input size='large' prefix={<UserOutlined />} placeholder='Email' />
      </Form.Item>
      <Form.Item name='password' rules={[{ required: true, message: 'Please enter your password!' }]}>
        <Input size='large' prefix={<LockOutlined />} type='password' placeholder='Password' />
      </Form.Item>

      <Button type='primary' htmlType='submit' block>
        Sign up
      </Button>
    </Form>
  );
};

export default SignupForm;
