import React from 'react';
import { Form, Input, Button, Checkbox } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

const LoginForm: React.FC<{
  register?: boolean;
  onLogin: (values: { email: string; password: string }) => void;
  toggle?: () => void;
}> = ({ register, onLogin, toggle }) => {
  return (
    <Form name='normal_login' onFinish={onLogin}>
      <Form.Item name='email' rules={[{ required: true, message: 'Please enter your email address!' }]}>
        <Input size='large' prefix={<UserOutlined />} placeholder='Email' />
      </Form.Item>
      <Form.Item name='password' rules={[{ required: true, message: 'Please enter your password!' }]}>
        <Input size='large' prefix={<LockOutlined />} type='password' placeholder='Password' />
      </Form.Item>
      {!register ? (
        <Form.Item>
          <div className='d-flex justify-content-end'>
            <Button type='link' htmlType='button' onClick={toggle}>
              Forgot password
            </Button>
          </div>
        </Form.Item>
      ) : null}

      <Form.Item>
        <Button type='primary' htmlType='submit' block>
          {register ? 'Login / Create' : 'Log in'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoginForm;
