import React, { useState } from 'react';
import { Button, Form, Input, Select, Checkbox, Divider } from 'antd';

import { RegQuestionModel } from '../../types';

const RegQuestions: React.FC<{
  regQuestions: RegQuestionModel[];
  initialValues: any;
  showContacts: boolean;
  onBack: (values: any) => void;
  onSubmit: (values: any) => void;
}> = ({ regQuestions, initialValues, showContacts, onBack, onSubmit }) => {
  const [form] = Form.useForm();
  console.log(regQuestions);

  const handleBackClick = () => {
    onBack(form.getFieldsValue());
  };

  const renderHeading = (item: RegQuestionModel) => {
    return (
      <div className='mb-4'>
        <h6>{item.itemText}</h6>
        {item.hasBottomLine ? <Divider /> : null}
      </div>
    );
  };
  const renderTextBlock = (item: RegQuestionModel) => {
    return (
      <div className='mb-4'>
        <div dangerouslySetInnerHTML={{ __html: item.itemText }} />
        {item.hasBottomLine ? <Divider /> : null}
      </div>
    );
  };
  const renderTextQuestion = (item: RegQuestionModel) => {
    return (
      <>
        <Form.Item
          name={[item.id, 'answer']}
          label={item.itemText}
          required={false}
          style={{ marginBottom: 8 }}
          rules={[{ required: item.required, message: 'Required' }]}
        >
          <Input />
        </Form.Item>

        {item.hasBottomLine ? <Divider /> : null}
      </>
    );
  };
  const renderSelectQuestion = (item: RegQuestionModel) => {
    return (
      <>
        <Form.Item
          name={[item.id, 'answer']}
          label={item.itemText}
          required={false}
          style={{ marginBottom: 8 }}
          rules={[{ required: item.required, message: 'Required' }]}
        >
          <Select
            mode={item.allowMutliResponse ? 'multiple' : undefined}
            options={item.itemValues ? item.itemValues.map((x) => ({ value: x, label: x })) : []}
          />
        </Form.Item>

        {item.allowComment && (
          <Form.Item name={[item.id, 'comment']} style={{ marginBottom: 8 }}>
            <Input placeholder={item.commentLabel} />
          </Form.Item>
        )}

        {item.hasBottomLine ? <Divider /> : null}
      </>
    );
  };
  const renderCheckboxQuestion = (item: RegQuestionModel) => {
    return (
      <>
        <Form.Item
          name={[item.id, 'answer']}
          required={false}
          style={{ marginBottom: 8 }}
          valuePropName='checked'
          rules={[{ required: item.required, message: 'Required' }]}
        >
          <Checkbox>{item.itemText}</Checkbox>
        </Form.Item>

        {item.allowComment && (
          <Form.Item name={[item.id, 'comment']} style={{ marginBottom: 8 }}>
            <Input placeholder={item.commentLabel} />
          </Form.Item>
        )}

        {item.hasBottomLine ? <Divider /> : null}
      </>
    );
  };

  const renderFormItem = (item: RegQuestionModel) => {
    switch (item.itemType) {
      case 'Heading':
        return renderHeading(item);
      case 'Text Block':
        return renderTextBlock(item);
      case 'Text Question':
        return renderTextQuestion(item);
      case 'Select Question':
        return renderSelectQuestion(item);
      case 'Checkbox Question':
        return renderCheckboxQuestion(item);
      default:
        return null;
    }
  };

  return (
    <>
      <Form
        form={form}
        layout='vertical'
        labelCol={{ span: 6 }}
        name='regQuestionForm'
        autoComplete='off'
        initialValues={initialValues}
        onFinish={onSubmit}
      >
        <h3 className='cardheader'>Registration Questions</h3>
        <div className='cardbody p-4 pr-5'>
          {regQuestions.map((item) => (
            <div key={item.id}>{renderFormItem(item)}</div>
          ))}
        </div>

        <div className='cardbody p-3 no-header'>
          <div className='d-flex justify-content-between'>
            <Button onClick={handleBackClick}>Back</Button>
            <Button type='primary' htmlType='submit'>
              {showContacts ? 'Next' : 'Submit Registration'}
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
};

export default RegQuestions;
