import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import PortalContext from '../../store/portal-context';
import UserOptions from './UserOptions';

const PortalHeader: React.FC<{}> = ({}) => {
  const portalCtx = useContext(PortalContext);
  const navigate = useNavigate();
  const { portalDetails, isLoggedIn, firstName, profilePic } = portalCtx;

  console.log('portalCtx', portalCtx);

  const handleLogOut = () => {
    portalCtx.onLogin({ isLoggedIn: false });
    localStorage.removeItem('token');
  };

  const handleRegistration = () => {
    portalCtx.onLogin({ isLoggedIn: false });
    navigate(`/registration/${portalDetails.schoolSlug}`);
  };

  return (
    <>
      <header id='header' style={{ background: portalDetails.portalColour }}>
        <div onClick={() => navigate('/')}>
          <div className='header-title'>{portalDetails.portalTitle}</div>
        </div>
        <div>{portalDetails.portalText}</div>
        {isLoggedIn && (
          <UserOptions
            firstName={firstName}
            profilePic={profilePic}
            onRegistration={handleRegistration}
            onlogOut={handleLogOut}
          />
        )}
      </header>
    </>
  );
};

export default PortalHeader;
