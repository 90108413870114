import React, { useContext, useEffect, useState } from 'react';
import Student from '../components/dashboard/Student';
import Emails from '../components/dashboard/Emails';
import StudentTabs from '../components/dashboard/StudentTabs';
import PortalContext from '../store/portal-context';

import api from '../api';

import { StudentData } from '../types';

const Dashboard: React.FC<{}> = ({}) => {
  const portalCtx = useContext(PortalContext);
  const { students } = portalCtx;
  const [studentData, setStudentData] = useState<StudentData>();
  const [studentId, setStudentId] = useState(students[0].id || 0);

  useEffect(() => {
    async function fetchStudentData() {
      const response = await api.get(`/portal/studentdata/${studentId}`);
      if (response.data) setStudentData(response.data);
    }

    fetchStudentData();
  }, [studentId]);

  if (!students || !studentData) return null;

  return (
    <div className='dashboard'>
      {students.length > 1 ? (
        <StudentTabs students={students} selectedStudent={studentId} onStudentChanged={setStudentId} />
      ) : null}
      <div className='d-flex mt-3'>
        <div className='w-50 mr-4'>
          <Student studentDetails={studentData.studentDetails} />
        </div>
        <div className='w-100'>
          <Emails emails={studentData.emails} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
