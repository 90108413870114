import React, { useState } from 'react';
import { Dropdown, Avatar, Modal } from 'antd';
import type { MenuProps } from 'antd';
import { UserOutlined, LogoutOutlined, UsergroupAddOutlined } from '@ant-design/icons';

const UserOptions: React.FC<{
  firstName: string;
  profilePic: string | null;
  onRegistration: () => void;
  onlogOut: () => void;
}> = ({ firstName, profilePic, onRegistration, onlogOut }) => {
  const [showModal, setShowModal] = useState(false);

  const onClick: MenuProps['onClick'] = ({ key }) => {
    if (key === 'signout') onlogOut();
    if (key === 'registration') onRegistration();
    if (key === 'contacts') setShowModal(true);
  };

  //   const renderSchoolList = (
  //     schools: { id: number; schoolId: number; schoolName: string }[],
  //     onSwapSchool: (id: number) => void
  //   ) =>
  //     schools.map((school) => ({
  //       label: school.schoolName,
  //       key: school.schoolId,
  //     }));

  const items: MenuProps['items'] = [];

  // schools.length > 1 && items.push(...renderSchoolList(schools, onSwapSchool), { type: 'divider' });

  items.push(
    { label: 'Manage Contacts', key: 'contacts', icon: <UserOutlined /> },
    { label: 'New Student Registration', key: 'registration', icon: <UsergroupAddOutlined /> },
    { type: 'divider' },
    { label: 'Sign Out', key: 'signout', icon: <LogoutOutlined /> }
  );

  return (
    <>
      <Dropdown className='list-inline-item' menu={{ items, onClick }} trigger={['click']} placement='bottomRight'>
        <a className='text-decoration-none text-white'>
          {profilePic ? <Avatar src={profilePic} size='small' /> : <Avatar icon={<UserOutlined />} size='small' />}
          <span className='avatar-text d-none d-md-inline pl-2 '>{firstName}</span>
        </a>
      </Dropdown>

      <Modal title={null} open={showModal} onCancel={() => setShowModal(false)} footer={null} centered destroyOnClose>
        <div />
      </Modal>
    </>
  );
};

export default UserOptions;
