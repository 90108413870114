import React, { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import PortalContainer from './components/layout/PortalContainer';
import AuthProvider from './components/layout/AuthProvider';
import PortalLogin from './pages/PortalLogin';
import Dashboard from './pages/Dashboard';
import PortalContext from './store/portal-context';
import PortalInvite from './pages/PortalInvite';
import Registration from './pages/Registration';

import 'antd/dist/reset.css';
import './App.css';

function App() {
  const portalCtx = useContext(PortalContext);

  if (!portalCtx.isLoggedIn) {
    return (
      <AuthProvider>
        <PortalContainer>
          <Routes>
            <Route path='/:slug?' element={<PortalLogin />} />
            <Route path='/invite/:token' element={<PortalInvite />} />
            <Route path='/registration/:slug' element={<Registration />} />
          </Routes>
        </PortalContainer>
      </AuthProvider>
    );
  }

  return (
    <PortalContainer>
      <Routes>
        <Route path='/' element={<Dashboard />} />
      </Routes>
    </PortalContainer>
  );
}

export default App;
