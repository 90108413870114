import React from 'react';
import { Radio } from 'antd';

type Student = {
  id: number;
  firstName: string;
};

const StudentTabs: React.FC<{
  students: Student[];
  selectedStudent: number;
  onStudentChanged: (value: number) => void;
}> = ({ students, selectedStudent, onStudentChanged }) => {
  const renderStudents = () => {
    return students.map((student) => {
      return (
        <Radio.Button key={student.id} value={student.id}>
          {student.firstName}
        </Radio.Button>
      );
    });
  };

  return students.length > 0 ? (
    <div className='text-right p-1'>
      <Radio.Group
        value={selectedStudent}
        onChange={(e) => onStudentChanged(e.target.value)}
        buttonStyle='solid'
        size='small'
      >
        {renderStudents()}
      </Radio.Group>
    </div>
  ) : null;
};

export default StudentTabs;
