import axios from 'axios';
import { ROOT_URL } from '../constants/keys';

const instance = axios.create({ baseURL: ROOT_URL });

instance.interceptors.request.use(
  async (config) => {
    if (config && config.headers) config.headers['x-auth'] = localStorage.getItem('token') || '';
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export default instance;
