import React from 'react';
import { Form, Input, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const PasswordForm: React.FC<{ onPasswordReset: (value: string) => void; toggle: () => void }> = ({
  onPasswordReset,
  toggle,
}) => {
  return (
    <Form name='normal_login' onFinish={onPasswordReset}>
      <Form.Item
        className='mb-1'
        name='email'
        rules={[{ required: true, message: 'Please enter your email address!' }]}
      >
        <Input size='large' prefix={<UserOutlined />} placeholder='Email' />
      </Form.Item>
      <div className='d-flex justify-content-end mb-3'>
        <Button type='link' htmlType='button' onClick={toggle}>
          Login Page
        </Button>
      </div>
      <Form.Item>
        <Button type='primary' htmlType='submit' block>
          Reset Password
        </Button>
      </Form.Item>
    </Form>
  );
};

export default PasswordForm;
