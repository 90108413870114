import React from 'react';
import { Descriptions } from 'antd';
import { formatDate } from '../helpers';

import { EmailModel } from '../../types';

const Email: React.FC<{ email: EmailModel }> = ({ email }) => {
  const { sentDate, emailSubject, emailFrom, emailAddress, emailBody } = email;

  return (
    <div className='p-1 pt-4'>
      <table className='emailTable'>
        <tr>
          <th>Date:</th>
          <td>{formatDate(sentDate)}</td>
        </tr>
        <tr>
          <th>From:</th>
          <td>{`${emailFrom} (${emailAddress})`}</td>
        </tr>
        <tr>
          <th>Subject:</th>
          <td>{emailSubject}</td>
        </tr>
        <tr>
          <td colSpan={2}>
            <div dangerouslySetInnerHTML={{ __html: emailBody }} />
          </td>
        </tr>
      </table>
    </div>
  );
};

export default Email;
