import React from 'react';
import { notification } from 'antd';
import { GoogleLogin } from '@react-oauth/google';

type GoogleSignInComponentProps = {
  isSignup?: boolean;
  onGoogle?: (token: string) => void;
};

const GoogleSignIn: React.FC<GoogleSignInComponentProps> = ({ isSignup, onGoogle }) => {
  return (
    <div className='d-flex justify-content-center'>
      <GoogleLogin
        width={350}
        text={isSignup ? 'signup_with' : 'signin_with'}
        onSuccess={(credentialResponse) => {
          if (credentialResponse.credential && onGoogle) onGoogle(credentialResponse.credential);
        }}
        onError={() => {
          console.log('Login Failed');
        }}
      />
    </div>
  );
};

export default GoogleSignIn;
