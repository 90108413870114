import React from 'react';
import { Button, Form, Input, Select, Checkbox, Divider } from 'antd';

import { RegField } from '../../types';

const ContactForm: React.FC<{
  addContact?: boolean;
  contactFields: RegField[];
  relationship: { value: string; label: string }[];
  initialValues: any;
  onRemove?: () => void;
  onSubmit: (values: any) => void;
}> = ({ addContact, contactFields, relationship, initialValues, onRemove, onSubmit }) => {
  const [form] = Form.useForm();

  const renderTextField = (field: string, label: string, required: boolean) => (
    <Form.Item
      name={field}
      label={label}
      required={required}
      style={{ marginBottom: 8 }}
      rules={[{ required: true, message: 'Required' }]}
    >
      <Input />
    </Form.Item>
  );

  const renderSelectField = (field: string, label: string, required: boolean, fieldValues?: string[]) => {
    const options = fieldValues ? fieldValues.map((item) => ({ value: item, label: item })) : [];

    return (
      <Form.Item
        name={field}
        label={label}
        required={required}
        style={{ marginBottom: 8 }}
        rules={[{ required: true, message: 'Required' }]}
      >
        <Select options={options} />
      </Form.Item>
    );
  };

  const renderCheckboxField = (field: string, label: string, required: boolean) => (
    <Form.Item
      name={field}
      label={label}
      required={required}
      style={{ marginBottom: 8 }}
      rules={[{ required: true, message: 'Required' }]}
    >
      <Checkbox />
    </Form.Item>
  );

  const renderContactFields = () => {
    return contactFields.map((field) => {
      switch (field.customType) {
        case 1:
          return renderTextField(field.fieldName, field.fieldLabel, field.requiredField);
        case 2:
          return renderSelectField(field.fieldName, field.fieldLabel, field.requiredField, field.fieldValues);
        case 3:
          return renderCheckboxField(field.fieldName, field.fieldLabel, field.requiredField);
        default:
          return null;
      }
    });
  };

  const renderButtons = () => {
    if (addContact)
      return (
        <Button type='primary' htmlType='submit'>
          Add
        </Button>
      );

    return (
      <div className='d-flex'>
        <Button className='mr-2' type='primary' htmlType='submit'>
          Save
        </Button>
        <Button danger onClick={onRemove}>
          Remove
        </Button>
      </div>
    );
  };

  return (
    <>
      <Form
        form={form}
        labelCol={{ span: 6 }}
        name='regContactForm'
        autoComplete='off'
        initialValues={initialValues}
        onFinish={onSubmit}
      >
        <Form.Item
          name='firstName'
          label='First Name'
          required={false}
          style={{ marginBottom: 8 }}
          rules={[{ required: true, message: 'Required' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name='lastName'
          label='Last Name'
          required={false}
          style={{ marginBottom: 8 }}
          rules={[{ required: true, message: 'Required' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name='email'
          label='Email'
          required={false}
          style={{ marginBottom: 8 }}
          rules={[
            { required: true, message: 'Required' },
            { type: 'email', message: 'Invalid email' },
          ]}
          validateTrigger='onBlur'
        >
          <Input readOnly={addContact && initialValues} />
        </Form.Item>

        {renderContactFields()}
        <Divider />
        {renderButtons()}
      </Form>
    </>
  );
};

export default ContactForm;
