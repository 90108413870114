import React, { useEffect, useContext, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Steps, notification, Spin } from 'antd';
import RegistrationInfo from '../components/registration/RegistrationInfo';
import StudentForm from '../components/registration/StudentForm';
import Contact from '../components/registration/Contact';
import RegLogin from '../components/registration/RegLogin';
import RegQuestions from '../components/registration/RegQuestions';
import PortalContext from '../store/portal-context';

import api from '../api';

import { RegDataModel, RegFormModel, LoginDetails } from '../types';

const Registration: React.FC<{}> = ({}) => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [linkedStudents, setLinkedStudents] = useState<string[]>([]);
  const [regStep, setRegStep] = useState(-1);
  const [regPage, setRegPage] = useState(0);
  const [regData, setRegData] = useState<RegDataModel>();
  const [regForm, setRegForm] = useState<RegFormModel>();
  const [showContacts, setShowContacts] = useState(false);
  const [stepItems, setStepItems] = useState<any[]>([
    {
      title: 'Student',
    },
    {
      title: 'Questions',
    },
  ]);
  const portalCtx = useContext(PortalContext);

  useEffect(() => {
    async function fetchRegistration() {
      try {
        const response = await api.get(`/portal/registration/${slug}`);
        if (response.data) {
          portalCtx.loadContext({ portalDetails: response.data.portalDetails });
          setRegData(response.data);
        }
      } catch (error) {
        navigate('/');
      }
    }

    fetchRegistration();
  }, []);

  useEffect(() => {
    if (regData && regStep === 0 && !stepItems.some((item) => item.title === 'Info & Terms')) {
      const {
        regDetails: { formText, formTerms },
      } = regData;
      if (formText.length > 20 || formTerms.length > 20) {
        setStepItems([{ title: 'Info & Terms' }, ...stepItems]);
      } else {
        setRegStep(0);
        setRegPage(2);
      }
    }
  }, [regData, regStep]);

  const onLogin = async (localContact: LoginDetails) => {
    setStepItems([...stepItems, { title: 'Contacts' }]);
    setShowContacts(true);
    setRegForm({ ...regForm, localContact });
    setRegStep(regStep + 1);
    setRegPage(regPage + 1);
  };

  const incrementStep = () => {
    setRegStep(regStep + 1);
    setRegPage(regPage + 1);
  };

  const decrementStep = () => {
    setRegStep(regStep - 1);
    setRegPage(regPage - 1);
  };

  const onRegInfo = async () => {
    setRegStep(regStep + 1);
    setRegPage(regPage + 1);
  };

  const onStudentBack = (values: any) => {
    setRegStep(regStep - 1);
    setRegPage(regPage - 1);
    setRegForm({ ...regForm, studentForm: { ...regForm?.studentForm, ...values } });
  };

  const onStudentSubmit = async (values: any) => {
    const formGroups = regData?.regDetails && regData?.regDetails.formGroups;
    const studentValues = formGroups && typeof formGroups === 'number' ? { ...values, groupId: formGroups } : values;
    const instrumentPrefrence = values.instrumentPrefrence.map((item: number) =>
      regData?.regDetails.instruments.find((i) => i.id === item)
    );

    if (linkedStudents.length > 0 && linkedStudents.includes(values.firstName.trim().toLowerCase())) {
      notification.warning({
        message: 'Student already registered',
        description: 'This student has already been registered. Please contact the schools music program for help.',
        duration: 7,
      });
    } else {
      incrementStep();
      setRegForm({ ...regForm, instrumentPrefrence, studentForm: { ...regForm?.studentForm, ...studentValues } });
    }
  };

  const onQuestionBack = (values: any) => {
    decrementStep();
    setRegForm({ ...regForm, questionForm: { ...regForm?.questionForm, ...values } });
  };

  const onQuestionSubmit = async (values: any) => {
    incrementStep();
    setRegForm({ ...regForm, questionForm: { ...regForm?.questionForm, ...values } });
  };

  const onContactBack = (values: any) => {
    console.log('onContactBack', values);
    decrementStep();
    setRegForm({ ...regForm, contactForm: [...values] });
  };

  const existingContact = async (token: string) => {
    incrementStep();
  };

  const onSubmitFormNoContacts = async (values: any) => {
    try {
      const response = await api.put('/portal/registration', {
        ...regForm,
        questionForm: values,
        schoolId: regData?.portalDetails.schoolId,
      });

      if (response.status === 200) {
        notification.success({ message: 'Registration Complete', duration: 7 });
        navigate('/');
      }
    } catch (error: any) {
      notification.warning({ message: error?.response.data || 'There was a problem submitting the form', duration: 7 });
    }
  };

  const onSubmitForm = async (values: any) => {
    try {
      const response = await api.post('/portal/registration', {
        ...regForm,
        contactForm: [...values],
        schoolId: regData?.portalDetails.schoolId,
      });

      if (response.data && response.data.token) {
        localStorage.setItem('token', response.data.token);
        notification.success({ message: 'Registration Complete', duration: 7 });
        navigate('/');
      }
    } catch (error: any) {
      notification.warning({ message: error?.response.data || 'There was a problem submitting the form', duration: 7 });
    }
  };

  const onGoogleContact = async (token: any) => {};

  if (!regData) return <Spin />;

  if (regPage === 0) {
    return (
      <div className='pt-4'>
        <RegLogin
          schoolId={regData.portalDetails.schoolId}
          linkedStudents={(students: string[]) => setLinkedStudents(students)}
          existingContact={existingContact}
          onLogin={onLogin}
        />
      </div>
    );
  }

  return (
    <>
      <Steps current={regStep} size='small' className='p-3 mb-3 pl-5 pr-5 bg-light' items={stepItems} />
      <div className='d-flex flex-column widthMedium pt-4'>
        {regPage === 1 && <RegistrationInfo regInfo={regData.regDetails} onNext={onRegInfo} />}
        {regPage === 2 && (
          <StudentForm
            studentFields={regData.studentFields}
            regInfo={regData.regDetails}
            onBack={onStudentBack}
            onSubmit={onStudentSubmit}
            initialValues={regForm?.studentForm}
            regSelects={regData.regSelects}
          />
        )}
        {regPage === 3 && (
          <RegQuestions
            regQuestions={regData.regQuestions}
            initialValues={regForm?.questionForm}
            onBack={onQuestionBack}
            onSubmit={showContacts ? onQuestionSubmit : onSubmitFormNoContacts}
            showContacts={showContacts}
          />
        )}
        {regPage === 4 && (
          <Contact
            contactFields={regData.contactFields}
            contacts={regForm?.contactForm}
            email={regForm?.localContact?.email || regForm?.googleContact?.email}
            onBack={onContactBack}
            onNext={onSubmitForm}
            relationship={(regData.regSelects && regData.regSelects.relationship) || []}
          />
        )}
      </div>
    </>
  );
};

export default Registration;
