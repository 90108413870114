import React from 'react';
import PortalHeader from './PortalHeader';
import PortalFooter from './PortalFooter';

const PortalContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className='outer-box'>
      <div className='inner-container'>
        <PortalHeader />
        <div className='content'>{children}</div>
      </div>
      <PortalFooter />
    </div>
  );
};

export default PortalContainer;
