import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../api';
import { notification } from 'antd';

import GoogleLogin from './GoogleLogin';
import SignupForm from './SignupForm';
import PortalContext from '../../store/portal-context';

const InviteSignup: React.FC<{ contactToken: string; emailCode: string | undefined }> = ({
  contactToken,
  emailCode,
}) => {
  const portalCtx = useContext(PortalContext);
  const navigate = useNavigate();

  const onLocal = async (values: { email: string; password: string }) => {
    const { email, password } = values;

    try {
      const response = await api.post('/portal/localsignup', {
        email,
        password,
        invitToken: contactToken,
        emailCode,
      });
      if (response?.data) {
        const { token } = response.data;
        localStorage.setItem('token', token);
        portalCtx.onLogin(Boolean(token));
        navigate('/');
      }
    } catch (error: any) {
      notification.warning({
        message: error?.response?.data?.message || 'There was a problem signing you in',
        duration: 7,
      });
    }
  };

  const onGoogle = async (token: string) => {
    try {
      const response = await api.post(`/portal/googlesignup/${contactToken}`, { token, emailCode });
      if (response?.data) {
        const { token } = response.data;
        localStorage.setItem('token', token);
        portalCtx.onLogin(Boolean(token));
        navigate('/');
      }
    } catch (error: any) {
      console.log(error?.response?.data);
      notification.warning({
        message: error?.response?.data?.message || 'There was a problem signing you in',
        duration: 7,
      });
    }
  };

  return (
    <div className='border rounded m-2 p-4'>
      <h5 className='text-center pb-3'>Sign up using</h5>
      <GoogleLogin isSignup onGoogle={onGoogle} />
      <div className='divider divider-with-content my-4'>
        <span className='divider-inner-content'>OR</span>
      </div>
      <div className='text-center mb-3'>Enter an email and password you would like to use</div>
      <SignupForm onSignup={onLocal} />
    </div>
  );
};

export default InviteSignup;
