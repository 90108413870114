import React, { Fragment, useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../api';
import { notification } from 'antd';

import GoogleSignIn from './GoogleLogin';
import LoginForm from './LoginForm';
import PasswordForm from './PasswordForm';
import PortalContext from '../../store/portal-context';

const Login: React.FC<{ schoolId: number }> = ({ schoolId }) => {
  const navigate = useNavigate();
  const portalCtx = useContext(PortalContext);
  const [forgotPassword, setForgotPassword] = useState(false);

  useEffect(() => {
    async function tokenLogin() {
      const token = localStorage.getItem('token');
      if (token) {
        const response = await api.get('/auth/token');
        if (response.data) {
          const { firstName, lastName, students, portalDetails } = response.data;
          portalCtx.onLogin({ isLoggedIn: true, firstName, lastName, students, portalDetails });
          navigate('/');
        }
      }
    }

    tokenLogin();
  }, []);

  const localLogin = async (values: { email: string; password: string }) => {
    const { email, password } = values;

    const response = await api.post('/portal/locallogin', { email, password, schoolId }).catch((error) => {
      notification.warning({ message: error?.response?.data || 'There was a problem signing you in', duration: 7 });
    });

    if (response?.data) {
      const { token, firstName, lastName, students, portalDetails } = response.data;
      console.log('response.data', response.data);
      localStorage.setItem('token', token);
      portalCtx.onLogin({ isLoggedIn: true, firstName, lastName, students, portalDetails });
      navigate('/');
    }
  };

  const onGoogleLogin = async (token: any) => {
    const result = await api.post('/auth/googlelogin', { token, schoolId }).catch((error) => {
      notification.warning({ message: error?.response?.data || 'There was a problem signing you in', duration: 7 });
    });
    if (result?.data) {
      const { token } = result.data;
      localStorage.setItem('token', token);
    }
  };

  const onPasswordReset = async (email: string) => {
    await api.post('/auth/password', email);
    notification.success({
      message: 'If your email address was found we will send you a password reset email',
      duration: 7,
    });
    setForgotPassword(false);
  };

  const toggle = () => {
    setForgotPassword(!forgotPassword);
  };

  if (forgotPassword) {
    return (
      <Fragment>
        <h2>Forgot Password</h2>
        <PasswordForm onPasswordReset={onPasswordReset} toggle={toggle} />
      </Fragment>
    );
  }

  return (
    <>
      <h3 className='cardheader'>Login</h3>
      <div className='cardbody'>
        <GoogleSignIn onGoogle={onGoogleLogin} />
        <div className='divider divider-with-content my-4'>
          <span className='divider-inner-content'>OR</span>
        </div>

        <LoginForm onLogin={localLogin} toggle={toggle} />
      </div>
    </>
  );
};

export default Login;
