if (process.env.NODE_ENV === 'production') {
  module.exports = {
    ROOT_URL: 'https://api.myschoolmusic.com.au',
    GOOGLE_ID: '277409029645-6rdcmnseikr58bmdscgnsti20ekefg15.apps.googleusercontent.com',
    RECAPTCHA_KEY: '6Ld3XKsUAAAAAPPvlkTkibwsqGxHNJIhh1gXC5o9',
    STRIPE_ID: 'ca_H9vQoDyzRB0RfZHtGhOBuEGXeXUid5GU',
    STRIPE_KEY: 'pk_live_pJCoFoBfN7vTRcXoJD8a1VLe00VOFYofmC',
  };
} else {
  module.exports = {
    ROOT_URL: 'http://localhost:3082',
    GOOGLE_ID: '277409029645-6rdcmnseikr58bmdscgnsti20ekefg15.apps.googleusercontent.com',
    RECAPTCHA_KEY: '6Ld3XKsUAAAAAPPvlkTkibwsqGxHNJIhh1gXC5o9',
    STRIPE_ID: 'ca_H9vQ64nGjgpb8Zw4tBUqhjuLRPbEYZSK',
    STRIPE_KEY: 'pk_test_t0E5Pa2yjEGM1mjVzy8xVigS00Kfoy7KlO',
  };
}
