import React, { useRef } from 'react';
import { Button, Input } from 'antd';

import { InputRef } from 'antd/lib/input';

const EmailValidate: React.FC<{ back: () => void; verifyCode: (emailCode: string | undefined) => void }> = ({
  back,
  verifyCode,
}) => {
  const emailCodeRef = useRef<InputRef>(null);

  const handleVerifyCode = () => {
    const emailCode = emailCodeRef?.current?.input?.value;
    verifyCode(emailCode);
  };

  return (
    <>
      <div className='w-500 center-card'>
        <h3 className='cardheader'>Email Validation</h3>
        <div className='cardbody'>
          <p className='pb-3'>To validate your email address, please enter the code from the email we sent you.</p>
          <div className='d-flex align-items-center border p-3 justify-content-center rounded'>
            <div className='p-1 text-nowrap'>Enter Code:</div>
            <div className='p-1'>
              <Input ref={emailCodeRef} onPressEnter={handleVerifyCode} />
            </div>
            <div className='p-1'>
              <Button type='primary' onClick={handleVerifyCode}>
                Verify
              </Button>
            </div>
          </div>
          <div className='d-flex justify-content-center pt-3'>
            <Button type='link' className='text-secondary' onClick={back}>
              Back to login - create account
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailValidate;
