import React, { useState, useEffect } from 'react';
import { Button, Tabs } from 'antd';
import ContactForm from './ContactForm';
import type { TabsProps } from 'antd';

import { ContactModel, RegField } from '../../types';

const Contact: React.FC<{
  contacts: ContactModel[];
  contactFields: RegField[];
  email?: string;
  relationship: { value: string; label: string }[];
  onBack: (values: any) => void;
  onNext: (values: any) => void;
}> = ({ contacts, contactFields, email, relationship, onBack, onNext }) => {
  const [studentContactsTabs, setStudentContactsTabs] = useState<TabsProps['items']>([]);
  const [studentContacts, setStudentContacts] = useState<ContactModel[]>(contacts || []);
  const [tabKey, setTabKey] = useState(contacts && contacts.length ? '0' : '4');
  const [submited, setSubmited] = useState(false);

  const handleAdd = (values: any) => {
    setStudentContacts([...studentContacts, values]);
    setTabKey(studentContacts.length.toString());
  };

  const handleUpdate = (values: any, index: number) => {
    const newContacts = [...studentContacts];
    newContacts[index] = values;
    setStudentContacts(newContacts);
  };

  const handleRemove = (index: number) => {
    const newContacts = [...studentContacts];
    newContacts.splice(index, 1);
    setStudentContacts(newContacts);
    setTabKey('4');
  };

  const handleSubmit = () => {
    setSubmited(true);
    onNext(studentContacts);
  };

  useEffect(() => {
    const items: TabsProps['items'] = studentContacts.map((contact, index) => ({
      key: index.toString(),
      label: contact.firstName,
      children: (
        <ContactForm
          contactFields={contactFields}
          relationship={relationship}
          initialValues={contact}
          onRemove={() => handleRemove(index)}
          onSubmit={(values) => handleUpdate(values, index)}
        />
      ),
    }));
    studentContacts.length < 4 &&
      items.push({
        key: '4',
        label: studentContacts.length > 0 ? 'Add Contact' : 'First Contact',
        children: (
          <ContactForm
            addContact
            contactFields={contactFields}
            relationship={relationship}
            initialValues={email && studentContacts.length === 0 ? { email } : undefined}
            onSubmit={handleAdd}
          />
        ),
      });

    setStudentContactsTabs(items);
  }, [studentContacts]);

  return (
    <>
      <h3 className='cardheader'>Contact Information</h3>
      <div className='cardbody p-4 pr-5'>
        <Tabs
          tabPosition='left'
          destroyInactiveTabPane
          items={studentContactsTabs}
          activeKey={tabKey}
          onTabClick={(e) => setTabKey(e)}
        />
      </div>

      <div className='cardbody p-3 no-header'>
        <div className='d-flex justify-content-between'>
          <Button onClick={() => onBack(studentContacts)}>Back</Button>
          <Button
            type='primary'
            onClick={handleSubmit}
            disabled={!studentContacts || studentContacts.length === 0 || submited}
          >
            Submit Registration
          </Button>
        </div>
      </div>
    </>
  );
};

export default Contact;
