import { createContext, useState } from 'react';
import { portalDetails } from '../types';

type portalContextModal = {
  isLoggedIn: boolean;
  firstName: string;
  lastName: string;
  profilePic: string;
  students: { id: number; firstName: string }[];
  portalDetails: portalDetails;
  loadContext: (values: { portalDetails: portalDetails }) => void;
  onLogin: (dat: any) => void;
};

const initialContext = {
  isLoggedIn: false,
  firstName: '',
  lastName: '',
  profilePic: '',
  students: [],
  portalDetails: {
    schoolId: 0,
    active: false,
    portalTitle: 'Parent Portal',
    portalColour: '#4a90e2',
    schoolSlug: '',
  },
  loadContext: () => {},
  onLogin: () => {},
};

const PortalContext = createContext<portalContextModal>({
  ...initialContext,
  loadContext: () => {},
  onLogin: () => {},
});

export const PortalContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [portalData, setPortalData] = useState<portalContextModal>(initialContext);

  const loadContextHandler = async (values: { portalDetails: portalDetails }) => {
    setPortalData({ ...portalData, ...values });
  };

  const onLoginHandler = async (data: any) => {
    setPortalData({ ...portalData, ...data });
  };

  const contextValue: portalContextModal = {
    ...portalData,
    loadContext: loadContextHandler,
    onLogin: onLoginHandler,
  };

  return <PortalContext.Provider value={contextValue}>{children}</PortalContext.Provider>;
};

export default PortalContext;
