import React, { useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Login from '../components/portal/Login';
import PortalContext from '../store/portal-context';

import api from '../api';

const PortalLogin: React.FC = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const portalCtx = useContext(PortalContext);
  const token = localStorage.getItem('token');
  const { schoolId, portalText } = portalCtx.portalDetails;

  useEffect(() => {
    async function fetchPortalDetails() {
      const response = await api.get(`/portal/portalderails/${slug}`);
      if (response.data) portalCtx.loadContext(response.data);
    }

    async function tokenLogin() {
      try {
        const response = await api.get('/portal/token');
        if (response.data) {
          portalCtx.onLogin({ isLoggedIn: true, ...response.data });
          navigate('/');
        } else {
          localStorage.removeItem('token');
        }
      } catch (error) {
        localStorage.removeItem('token');
        navigate('/');
      }
    }

    if (token) {
      tokenLogin();
    } else if (slug) {
      fetchPortalDetails();
    }
  }, []);

  if (token) return null;

  const renderRegButton = () => {
    if (portalCtx.portalDetails.active) {
      return (
        <div className='w-400 cardbody no-header'>
          <button
            type='button'
            className='btn btn-secondary btn-block'
            onClick={() => navigate(`/registration/${slug}`)}
          >
            New Student Registration
          </button>
        </div>
      );
    }
    return null;
  };

  if (!slug || !portalText)
    return (
      <div className='d-flex flex-column align-items-center pt-4'>
        <div className='w-400'>
          <Login schoolId={schoolId} />
        </div>

        {renderRegButton()}
      </div>
    );

  return (
    <div className='d-flex pt-4'>
      <div className='w-100 mr-4'>
        <div className='no-header cardbody'>
          <div dangerouslySetInnerHTML={{ __html: portalText }} />
        </div>
      </div>
      <div className='w-50'>
        <Login schoolId={portalCtx.portalDetails.schoolId} />
      </div>
      {renderRegButton()}
    </div>
  );
};

export default PortalLogin;
