import React, { useState } from 'react';
import { Button, Checkbox } from 'antd';

import { RegDetailsModel } from '../../types';

const RegistrationInfo: React.FC<{ regInfo: RegDetailsModel; onNext: () => void }> = ({ regInfo, onNext }) => {
  const [checked, setChecked] = useState(false);
  return (
    <>
      {regInfo.formText.length > 20 && (
        <>
          <h3 className='cardheader'>Registration Infomation</h3>
          <div className='cardbody'>
            <div dangerouslySetInnerHTML={{ __html: regInfo.formText }} />
          </div>
        </>
      )}

      {regInfo.formTerms.length > 20 && (
        <>
          <h3 className='cardheader'>Terms and Conditions</h3>
          <div className='cardbody'>
            <div dangerouslySetInnerHTML={{ __html: regInfo.formTerms }} />
          </div>
        </>
      )}

      <div className='cardbody p-3 no-header'>
        <div className='d-flex justify-content-between align-items-center'>
          <Checkbox onChange={(e) => setChecked(e.target.checked)}>I accpet the terms and conditions</Checkbox>
          <Button type='primary' disabled={!checked} onClick={onNext}>
            Next
          </Button>
        </div>
      </div>
    </>
  );
};

export default RegistrationInfo;
