import React, { useState } from 'react';
import { Table, Modal, Spin } from 'antd';
import Email from '../modals/Email';
import { formatDate } from '../helpers';

import api from '../../api';

import { EmailList, EmailModel } from '../../types';

const Emails: React.FC<{ emails: EmailList[] }> = ({ emails }) => {
  const [emailContent, setEmailContent] = useState<EmailModel>();
  const [modalVisible, setModalVisible] = useState(false);

  const handleRowClicked = async (id: number) => {
    const response = await api.get(`/portal/emailcontent/${id}`);
    if (response.data) {
      setEmailContent(response.data.emailDetails);
      setModalVisible(true);
    }
  };

  const columns = [
    {
      title: 'Date',
      dataIndex: 'sentDate',
      key: 'date',
      width: '100px',
      render: (date: string) => formatDate(date),
    },
    {
      title: 'From',
      dataIndex: 'emailFrom',
      key: 'from',
      width: '170px',
    },
    {
      title: 'Subject',
      dataIndex: 'emailSubject',
      key: 'subject',
    },
  ];

  return (
    <>
      <h3 className='cardheader'>Emails</h3>
      <div className='cardbody'>
        {
          <Table
            rowKey='id'
            columns={columns}
            dataSource={emails}
            pagination={false}
            size='small'
            bordered
            scroll={{ y: 300 }}
            onRow={(row) => {
              return {
                onClick: () => handleRowClicked(row.id),
              };
            }}
          />
        }
      </div>
      <Modal width={850} open={modalVisible} footer={null} onCancel={() => setModalVisible(false)}>
        {emailContent ? <Email email={emailContent} /> : <Spin />}
      </Modal>
    </>
  );
};

export default Emails;
