import React, { useEffect, useContext, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Input, notification } from 'antd';
import PortalContext from '../store/portal-context';
import InviteSignup from '../components/portal/InviteSignup';

import api from '../api';
import { InputRef } from 'antd/lib/input';

const PotalInvite: React.FC<{}> = ({}) => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const emailCodeRef = useRef<InputRef>(null);
  const portalCtx = useContext(PortalContext);

  useEffect(() => {
    async function fetchInvite() {
      try {
        const response = await api.get(`/portal/invite/${token}`);
        if (!response.data) {
          navigate('/');
        } else {
          const { portalTitle, portalColour, portalLogo, email, schoolId, active } = response.data;
          setEmail(email);
          const portalDetails = {
            schoolId,
            active,
            portalTitle,
            portalColour,
            portalLogo,
            schoolSlug: '',
          };
          portalCtx.loadContext({ portalDetails });
        }
      } catch (error) {
        navigate('/');
      }
    }

    fetchInvite();
  }, []);

  const handleSendCode = async () => {
    try {
      const response = await api.get(`/portal/sendcode/${token}`);
      if (response?.status === 200) {
        notification.success({ message: 'Code sent', duration: 7 });
        setCodeSent(true);
      }
    } catch (error: any) {
      notification.warning({
        message: error?.response?.data?.message || 'There was a problem sending the code',
        duration: 7,
      });
    }
  };

  const handleVerifyCode = async () => {
    const emailCode = emailCodeRef?.current?.input?.value;
    if (emailCode) {
      try {
        const response = await api.post(`/portal/verifycode/${token}`, { emailCode });
        if (response?.status === 200) {
          setIsVerified(true);
        }
      } catch (error: any) {
        notification.warning({
          message: error?.response?.data?.message || 'There was a problem verifying your email',
          duration: 7,
        });
      }
    }
  };

  const renderEmailCode = () => (
    <>
      <div className='text-center'>To verify your email we will send a code to: {email}</div>
      <div className='p-4 text-center'>
        <Button type='primary' disabled={codeSent} onClick={handleSendCode}>
          Send Code
        </Button>
      </div>
      <div className='d-flex align-items-center border p-3 justify-content-center rounded'>
        <div className='p-1 text-nowrap'>Enter Code:</div>
        <div className='p-1'>
          <Input ref={emailCodeRef} onPressEnter={handleVerifyCode} />
        </div>
        <div className='p-1'>
          <Button type='primary' disabled={!codeSent} onClick={handleVerifyCode}>
            Verify
          </Button>
        </div>
      </div>
    </>
  );

  if (!token) return null;

  return (
    <div className='d-flex justify-content-center'>
      <div className='w-500 mr-4'>
        <h3 className='cardheader'>Parent Portal Registration</h3>
        <div className='cardbody'>
          {isVerified ? (
            <InviteSignup contactToken={token} emailCode={emailCodeRef?.current?.input?.value} />
          ) : (
            renderEmailCode()
          )}
        </div>
      </div>
    </div>
  );
};

export default PotalInvite;
